import React from "react"
import { Subheading, Card, Icon, Stack } from "@shopify/polaris"
import {
  ChevronUpMinor,
  ChevronDownMinor,
  DeleteMajor,
} from "@shopify/polaris-icons"
import update from "immutability-helper"
import styled from "styled-components"
const DeleteWrapper = styled.div`
  cursor: pointer;
`
const EditWrapper = styled.div`
  cursor: pointer;
  color: #008060;
  text-decoration: underline;
`

function ProductActions(props) {
  const {
    product,
    products,
    offerIndex,
    productIndex,
    state,
    setState,
    productEdit,
    setProductEdit,
  } = props
  const deleteOneProduct = (index) => {
    setState(
      update(state, {
        product: { [offerIndex]: { products: { $splice: [[index, 1]] } } },
      })
    )
    if (index < productEdit[1]) {
      setProductEdit([offerIndex, productEdit - 1])
    }
  }

  const moveItem = (from, to) => {
    const data = products
    const f = data.splice(from, 1)[0]
    data.splice(to, 0, f)
    setState(
      update(state, {
        product: { [offerIndex]: { products: { $set: [...data] } } },
      })
    )
  }
  const moveUp = (index) => {
    moveItem(index, index - 1)
  }
  const moveDown = (index) => {
    moveItem(index, index + 1)
  }
  return (
    <Card sectioned key={productIndex}>
      <Stack alignment="center">
        <Stack.Item fill>
          <Stack alignment="center">
            <img
              className="upsellplus-image"
              src={
                (product.featuredImage && product.featuredImage) ||
                "https://via.placeholder.com/200"
              }
              style={{ width: "20px", height: "20px" }}
            />
            <Subheading>
              product {productIndex + 1}: {" "}
              {product.product
                ? product.product.title
                : "please choose product"}
            </Subheading>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack alignment="center">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  border: "1px solid #C9CCCF",
                  borderRadius: "5px 0 0 5px",
                  borderRight: "none",
                  padding: "3px 1px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (productIndex > 0) {
                    moveUp(productIndex)
                  }
                }}
              >
                <Icon
                  source={ChevronUpMinor}
                  color={productIndex > 0 ? "base" : "subdued"}
                />
              </div>
              <div
                style={{
                  border: "1px solid #C9CCCF",
                  borderRadius: "0 5px 5px 0",
                  padding: "3px 1px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (productIndex < products.length - 1) {
                    moveDown(productIndex)
                  }
                }}
              >
                <Icon
                  source={ChevronDownMinor}
                  color={
                    productIndex < products.length - 1 ? "base" : "subdued"
                  }
                />
              </div>
            </div>
            <EditWrapper
              onClick={() => {
                setProductEdit([offerIndex,productIndex])
              }}
            >
              <a>EDIT</a>
            </EditWrapper>
            <DeleteWrapper onClick={() => deleteOneProduct(productIndex)}>
              <Icon source={DeleteMajor} color="critical" />
            </DeleteWrapper>
          </Stack>
        </Stack.Item>
      </Stack>
    </Card>
  )
}

export default ProductActions
